import React from 'react'

// Components
import Card from 'components/elements/Card'

// Third Party
import styled from 'styled-components'
import ButtonPrimaryOutlined from 'components/elements/Buttons/ButtonPrimaryOutlined'

interface UspHorizontalProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledUspHorizontal = styled.section`
  position: relative;
  padding: 30px 0 0 0;

  @media (min-width: 768px) {
    padding: 85px 0 0 0;
  }
`

const Background = styled.div`
  background-color: ${({ theme }) => theme.color.tertiary};
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 1;
  height: 144px;

  @media (max-width: 575px) {
    height: 90px;
  }
`

const Container = styled.div`
  position: relative;
  z-index: 2;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;

  @media (min-width: 768px) {
    grid-gap: 15px;
    width: 100%;
  }

  @media (min-width: 1200px) {
    grid-gap: 55px;
    width: 1015px;
  }
`

const Column = styled.div`
  @media (max-width: 575.98px) {
    grid-column: span 3;
  }

  @media (min-width: 576px) {
    grid-column: span 1 / span 1;
  }
`

const UspHorizontal: React.FC<UspHorizontalProps> = ({ fields }) => (
  <StyledUspHorizontal>
    <Background />
    <Container className="container">
      <div className="d-flex justify-content-center">
        <Grid>
          {fields.usps?.map((usp, index) => (
            <Column key={index}>
              <Card fields={usp} />
            </Column>
          ))}
        </Grid>
      </div>
      <div className="d-flex justify-content-center mt-3 pt-5">
        <ButtonPrimaryOutlined to={fields.link?.url || '/'}>
          {fields.link?.title}
        </ButtonPrimaryOutlined>
      </div>
    </Container>
  </StyledUspHorizontal>
)

export default UspHorizontal
